import { ImageListItem, ImageList } from "@mui/material";

import gallery1 from './../../../assets/gallery/gallery-1.jpeg';
import gallery2 from './../../../assets/gallery/gallery-2.jpeg';
import gallery3 from './../../../assets/gallery/gallery-3.jpeg';
import gallery4 from './../../../assets/gallery/gallery-4.jpeg';
import gallery5 from './../../../assets/gallery/gallery-5.jpeg';
import gallery6 from './../../../assets/gallery/gallery-6.jpeg';
import gallery7 from './../../../assets/gallery/gallery-7.jpeg';
import gallery8 from './../../../assets/gallery/gallery-8.jpeg';
import gallery9 from './../../../assets/gallery/gallery-9.jpeg';
import gallery10 from './../../../assets/gallery/gallery-10.jpeg';
import gallery11 from './../../../assets/gallery/gallery-11.jpeg';
import gallery12 from './../../../assets/gallery/gallery-12.jpeg';
import gallery13 from './../../../assets/gallery/gallery-13.jpeg';
import gallery14 from './../../../assets/gallery/gallery-14.jpeg';
import gallery15 from './../../../assets/gallery/gallery-15.jpeg';
import gallery16 from './../../../assets/gallery/gallery-16.jpeg';
import gallery17 from './../../../assets/gallery/gallery-17.jpeg';
import gallery18 from './../../../assets/gallery/gallery-18.jpeg';
import gallery19 from './../../../assets/gallery/gallery-19.jpeg';

import './Gallery.module.css';

const photos = [
  {
    src: gallery1,
    width: 715,
    height: 1045,
  },
  {
    src: gallery2,
    width: 1536,
    height: 2048,
  },
  {
    src: gallery3,
    width: 1536,
    height: 2048,
  },
  {
    src: gallery4,
    width: 496,
    height: 372,
  },
  {
    src: gallery5,
    width: 1536,
    height: 2048,
  },
  {
    src: gallery6,
    width: 2048,
    height: 1536,
  },
  {
    src: gallery7,
    width: 1024,
    height: 577,
  },
  {
    src: gallery8,
    width: 2048,
    height: 1536,
  },
  {
    src: gallery9,
    width: 1536,
    height: 2048,
  },
  {
    src: gallery10,
    width: 2048,
    height: 1536,
  },
  {
    src: gallery11,
    width: 1536,
    height: 2048,
  },
  {
    src: gallery12,
    width: 1536,
    height: 2048,
  },
  {
    src: gallery13,
    width: 2048,
    height: 1536,
  },
  {
    src: gallery14,
    width: 1536,
    height: 2048,
  },
  {
    src: gallery15,
    width: 2048,
    height: 1536,
  },
  {
    src: gallery16,
    width: 2048,
    height: 1536,
  },
  {
    src: gallery17,
    width: 1536,
    height: 2048,
  },
  {
    src: gallery18,
    width: 1536,
    height: 2048,
  },
  {
    src: gallery19,
    width: 1536,
    height: 2048,
  },
];

const Gallery = () => {
    return (
      <ImageList variant="masonary" cols={3} rowHeight='auto'>
      {photos.map((item) => (
        <ImageListItem key={item.src}>
          <img
            src={item.src}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
      );
  };
  
export default Gallery;