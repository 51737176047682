import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/common/Navbar/Navbar';
import Home from './components/screens/Home/Home';
import AboutUs from './components/screens/AboutUs/AboutUs';
import Contact from './components/screens/Contact/Contact';
import Gallery from './components/screens/Gallery/Gallery';
import Services from './components/screens/Services/Services';

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/services" element={<Services/>} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/gallery" element={<Gallery />} />
      </Routes>
    </Router>
  );
};

export default App;